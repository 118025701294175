// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form", "sponsorships"];

  connect() {
    const id_count = document.querySelector('#preregistration_idaho_emp_count').value;
    const or_count = document.querySelector('#preregistration_oregon_emp_count').value;
    const wa_count = document.querySelector('#preregistration_washington_emp_count').value

    const contest_counts = {
      or: or_count,
      id: id_count,
      wa: wa_count
    }

    const localContests = this.getLocalContests();
    localContests.forEach(function (checkbox) {
      if (checkbox.disabled === false && checkbox.checked === false) checkbox.click();
    });
    const statewideContests = this.getStatewideContests()
    statewideContests.forEach(function (checkbox) {
      if (contest_counts[checkbox.dataset.contest] > 9) {
        if (checkbox.disabled === false && checkbox.checked === false) checkbox.click();
      }
    });
    const vrp_checkbox = document.getElementById("vrp_all_report");
    const ecr_checkbox = document.getElementById("ecr_all_report");
    const bdr_checkbox = document.getElementById("bdr_all_report");
    if (vrp_checkbox.checked == false) vrp_checkbox.click();
    if (bdr_checkbox.checked == false) bdr_checkbox.click();

    if (vrp_checkbox.checked === true) {
      ecr_checkbox.disabled = true;
    }
    this.updateTotal();
    console.log("connected");
  }

  updateTotal() {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    let total = 0;
    const total_fields = document.querySelectorAll("[id$='_net_price']");
    total_fields.forEach(function (ttl) {
      if (ttl.value) total += Number(ttl.value.replaceAll(/[$,]/g, ""));
    });
    document.getElementById("total_of_services").value = USDollar.format(total);
  }

  toggleSponsorships(e) {
    e.preventDefault();
    document.getElementById("sponContainer").classList.toggle("hidden")
    document.getElementById("interested-in-sponsoring").classList.toggle("hidden")
  }

  togglePurchase(e) {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    const contest = e.target.dataset.contest;
    const applied_field = document.getElementById(`${contest}_ccc_applied`);
    const net_price = document.getElementById(`${contest}_net_price`);
    const credit = document.getElementById(`${contest}_credit`);
    const ecr_checkbox = document.getElementById("ecr_report");
    if (e.target.checked === true) {
      if (e.target.id === "vrp_report") {
        if (ecr_checkbox.checked === true) {
          ecr_checkbox.click();
        }
        ecr_checkbox.disabled = true;
      }
      const applyCredit = document.getElementById(`${contest}_ccm`).innerHTML;
      const creditValue = credit.value.replaceAll(/[$,]/g, "");
      const price = document
        .getElementById(`${contest}_price`)
        .value.replaceAll(/[$,]/g, "");
      if (applyCredit === "Y") {
        applied_field.value = "Y";
      } else {
        applied_field.value = "N";
      }
      const creditToApply = applied_field.value;
      if (creditToApply === "Y") {
        net_price.value = USDollar.format(Number(creditValue) + Number(price));
      } else {
        net_price.value = USDollar.format(Number(price));
      }
    } else {
      applied_field.value = "";
      net_price.value = "";
      if (e.target.id === "vrp_report") {
        ecr_checkbox.disabled = false;
      }
    }
    this.updateTotal();
  }

  validateCart(e) {
    let valid = true;

    const statewideContests = [...this.getStatewideContests()];
    const idLocalContests = ["ID", ...this.getLocalContests("ID")];
    const orLocalContests = ["OR", ...this.getLocalContests("OR")];
    const waLocalContests = ["WA", ...this.getLocalContests("WA")];

    // Ensure at least 1 statewide contest is selected
    if (!statewideContests.some(c => c.checked)) {
      alert("At least 1 statewide Contest must be purchased.");
      valid = false;
    }

    // Ensure that at least 1 local contest is checked
    const allLocalContests = [...this.getLocalContests()]
    if (!allLocalContests.some(c => c.checked)) {
      alert("At least 1 local contest must be purchased")
      valid = false;
    }

    // Loop through each state contest list
    for (const contests of [idLocalContests, orLocalContests, waLocalContests]) {
      if (contests.length > 1) {
        const [st, ...cons] = contests;
        const localOrdered = cons.some(con => con.checked);

        if (localOrdered) {

          // Check if a statewide contest exists for this state
          const exists = statewideContests.some(con =>
            con.dataset?.contest === st.toLowerCase() && con.checked
          );

          if (!exists) {
            alert(`A State contest must be added for each state with a local contest selected: ${st}`);
            valid = false;
          }
        }
      }
    }

    if (!valid) {
      e.preventDefault();
    }
  }

  getStatewideContests() {
    return document.querySelectorAll(
      '.registration input[type="checkbox"][name^="preregistration[BPTW-REG-ST-"]'
    );
  }

  getLocalContests(state = "") {
    return document.querySelectorAll(
      `.registration input[type="checkbox"][name^="preregistration[BPTW-REG-LOC-${state}"]`
    );
  }
}
